const palette = {
  black: '#000',
  blackLight: '#333',
  danger: 'red',
  green: 'green',
  greyBorder: '#ced4da',
  grey: '#a9a9a9',
  greyDark: '#656567',
  meta: '#454547',
  primary: '#db4c3f',
  white: '#fff'
};

export default palette;
