import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { Item } from '../../../types/schemas';
import palette from '../../../theme/palette';
import { addToBasket } from '../../../actions/basket.actions';
import typography from '../../../theme/typography';
import Card from '../../../components/Card';
import { itemBasketCountSelector } from '../../../store/selectors';
import { formatMoney, formatNumber } from '../../../lib/utils';

type Props = {
  item: Item;
};

const ItemListing: React.FC<Props> = ({ item }) => {
  const [quantity, setQuantity] = useState('1');
  const itemBasketCount = useSelector(itemBasketCountSelector(item.id));
  const dispatch = useDispatch();

  const handleAddToBasket = () => dispatch(addToBasket(item, +quantity));

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuantity(event.target.value);
  };

  /**
   * Display item price.
   */
  const displayPrice = () => {
    // Handle weighted items
    if (item.unitType === 'weight') {
      return (
        <>
          {formatMoney(item.price * item.unitSize)}

          <span className="item__price--meta">
            {formatMoney(item.price)} / kg
          </span>
        </>
      );
    }

    // Handle any other item types
    return formatMoney(item.price);
  };

  return (
    <StyledItemListing>
      <h3 className="item__name">
        {item.name}
        {itemBasketCount > 0 && (
          <span className="item__quantity">
            ({formatNumber(itemBasketCount)} in basket)
          </span>
        )}
      </h3>

      <div className="item__price">{displayPrice()}</div>

      <div className="item__add">
        <FormControl
          value={quantity}
          type="number"
          min="1"
          onChange={handleQuantityChange}
          data-testid="item-quantity"
        />

        <Button
          variant="outline-primary"
          onClick={handleAddToBasket}
          data-testid="add-item-btn"
        >
          Add
        </Button>
      </div>
    </StyledItemListing>
  );
};

const StyledItemListing = styled(Card)`
  margin-bottom: 20px;

  .item {
    &__quantity {
      color: ${palette.grey};
      font-size: 15px;
      margin-left: 10px;
    }

    &__price {
      margin-bottom: 10px;
      font-weight: ${typography.fontWeights.robotoRegular};

      &--meta {
        color: ${palette.grey};
        display: inline-block;
        font-size: 15px;
        margin-left: 15px;
      }
    }

    &__add {
      display: flex;
    }
  }
`;

export default ItemListing;
