import styled from 'styled-components';
import typography from '../../../theme/typography';

const Total = styled.div`
  font-size: 30px;
  font-weight: ${typography.fontWeights.robotoBold};
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: right;
`;

export default Total;
