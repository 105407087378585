import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import store from '../store';
import GlobalStyle from '../theme/GlobalStyle';

const AppProvider: React.FC = ({ children }) => (
  <StoreProvider store={store}>
    <GlobalStyle />
    {children}
  </StoreProvider>
);

export default AppProvider;
