// eslint-disable-next-line import/named
import { Selector, createSelector } from 'reselect';
import { BasketItem } from '../reducers/basketReducer';
import { Item } from '../types/schemas';
import { calculateItemSubtotal, calculateSavings } from '../lib/calculations';
import { StoreState } from './index';

/**
 * Get the list of items.
 */
export const itemsSelector: Selector<StoreState, Item[] | null> = state =>
  state.products.items;

/**
 * Get the basket items.
 */
export const basketItemsSelector: Selector<StoreState, BasketItem[]> = state =>
  state.basket.items;

/**
 * Get subtotal of basket (before any savings are applied).
 */
export const basketSubtotalSelector = createSelector(
  basketItemsSelector,
  items =>
    items.reduce(
      (total, { item, quantity }) =>
        total + calculateItemSubtotal(item, quantity),
      0
    )
);

/**
 * Get list of savings.
 */
export const basketSavingsSelector = createSelector(
  basketItemsSelector,
  calculateSavings
);

/**
 * Get total savings.
 */
export const basketSavingsTotalSelector = createSelector(
  basketSavingsSelector,
  savings =>
    savings.reduce((total, { offerTotalSaving }) => total + offerTotalSaving, 0)
);

/**
 * Get total to pay.
 */
export const basketTotalPaySelector = createSelector(
  basketSubtotalSelector,
  basketSavingsTotalSelector,
  (subtotal, savingsTotal) => subtotal - savingsTotal
);

/**
 * Get count of a given item in basket.
 */
export const itemBasketCountSelector = (itemId: number) =>
  createSelector(basketItemsSelector, items => {
    const item = items.find(({ item }) => item.id === itemId);

    return item ? item.quantity : 0;
  });
