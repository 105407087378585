import { combineReducers, Reducer } from 'redux';
import { StoreState } from '../store';
import productsReducer from './productsReducer';
import basketReducer from './basketReducer';

export type StoreAction = {
  type: string;
  payload?: any;
};

const appReducer: Reducer<StoreState, StoreAction> = combineReducers({
  basket: basketReducer,
  products: productsReducer
});

export default appReducer;
