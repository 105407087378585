import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { useDispatch } from 'react-redux';
import { BasketItem as TBasketItem } from '../../../reducers/basketReducer';
import typography from '../../../theme/typography';
import {
  removeFromBasket,
  updateQuantity
} from '../../../actions/basket.actions';
import Card from '../../../components/Card';
import { calculateItemSubtotal } from '../../../lib/calculations';
import { formatMoney } from '../../../lib/utils';

type Props = {
  item: TBasketItem;
};

const BasketItem: React.FC<Props> = ({ item: { item, quantity } }) => {
  const [quantityValue, setQuantityValue] = useState(quantity.toString());
  const dispatch = useDispatch();

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) =>
    setQuantityValue(event.target.value);

  const handleUpdateQuantity = () =>
    dispatch(updateQuantity(item.id, +quantityValue));

  const handleRemove = () => dispatch(removeFromBasket(item.id));

  return (
    <StyledBasketItem>
      <div className="header">
        <h3 className="name">{item.name}</h3>

        <Button variant="outline-danger" size="sm" onClick={handleRemove}>
          Remove
        </Button>
      </div>

      <div className="quantity">
        <FormControl
          value={quantityValue}
          type="number"
          min="1"
          onChange={handleQuantityChange}
        />

        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleUpdateQuantity}
        >
          Update
        </Button>
      </div>

      <div className="total">
        {formatMoney(calculateItemSubtotal(item, quantity))}
      </div>
    </StyledBasketItem>
  );
};

const StyledBasketItem = styled(Card)`
  margin-bottom: 20px;

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .quantity {
    display: flex;
    margin-bottom: 15px;
  }

  .total {
    font-size: 20px;
    font-weight: ${typography.fontWeights.robotoRegular};
    text-align: right;
  }
`;

export default BasketItem;
