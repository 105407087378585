// Routes
export const SHOP_ROUTE = '/';
export const BASKET_ROUTE = '/basket';

// Storage keys
export const STORAGE_KEY_BASKET = 'basket';

// Savings names
export const THREE_FOR_TWO = '3 for 2';
export const TWO_FOR_ONE_POUND = '2 for £1';
