import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/layout';
import Container from '../../components/layout/Container';
import { PageTitle } from '../../components/typography';
import LoadingScreen from '../../components/LoadingScreen';
import { fetchProducts } from '../../actions/products.actions';
import { itemsSelector } from '../../store/selectors';
import ItemListing from './components/ItemListing';

const ShopView: React.FC = () => {
  const dispatch = useDispatch();
  const items = useSelector(itemsSelector);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  if (!items) {
    return <LoadingScreen />;
  }

  return (
    <Layout data-testid="shop-view">
      <Container>
        <PageTitle>Shop.</PageTitle>

        <p>Choose from our amazing list of products.</p>

        {items.map(product => (
          <ItemListing key={product.id} item={product} />
        ))}
      </Container>
    </Layout>
  );
};

export default ShopView;
