import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import palette from '../../theme/palette';
import { formatMoney } from '../../lib/utils';
import { basketTotalPaySelector } from '../../store/selectors';
import { BASKET_ROUTE, SHOP_ROUTE } from '../../lib/constants';
import Container from './Container';

const Header: React.FC = () => {
  const totalPay = useSelector(basketTotalPaySelector);

  return (
    <StyledHeader bg="primary" variant="primary" fixed="top">
      <Container className="container">
        <Navbar.Brand className="brand">
          <Link to={SHOP_ROUTE}>iShop</Link>
        </Navbar.Brand>

        <Navbar.Toggle />

        <Navbar.Collapse
          className="justify-content-end"
          data-testid="basket-btn"
        >
          <Link to={BASKET_ROUTE}>
            <Navbar.Text>Basket ({formatMoney(totalPay)})</Navbar.Text>
          </Link>
        </Navbar.Collapse>
      </Container>
    </StyledHeader>
  );
};

export const HEADER_HEIGHT = 60;

const StyledHeader = styled(Navbar)`
  color: ${palette.white};
  height: ${HEADER_HEIGHT}px;
  padding-left: 10px;
  padding-right: 10px;

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .brand {
    font-size: 24px;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
`;

export default Header;
