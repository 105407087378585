import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  basketItemsSelector,
  basketSubtotalSelector
} from '../../../store/selectors';
import { Subheading } from '../../../components/typography';
import { formatMoney } from '../../../lib/utils';
import BasketItem from './BasketItem';
import Subtotal from './Subtotal';

const BasketItems: React.FC = () => {
  const items = useSelector(basketItemsSelector);
  const subtotal = useSelector(basketSubtotalSelector);

  return (
    <StyledBasketItems>
      <Subheading>Items</Subheading>

      {items.map(item => (
        <BasketItem key={item.item.id} item={item} />
      ))}

      <Subtotal>Subtotal: {formatMoney(subtotal)}</Subtotal>
    </StyledBasketItems>
  );
};

const StyledBasketItems = styled.div``;

export default BasketItems;
