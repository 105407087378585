import styled from 'styled-components';
import palette from '../theme/palette';

const Card = styled.div`
  background: ${palette.white};
  border: 1px solid ${palette.greyBorder};
  padding: 20px;
`;

export default Card;
