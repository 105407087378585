import React from 'react';
import styled from 'styled-components';
import spinnerGifUrl from '../spinner.gif';

const LoadingScreen: React.FC = () => (
  <StyledLoadingScreen>
    <img src={spinnerGifUrl} alt="Loading spinner" />
  </StyledLoadingScreen>
);

const StyledLoadingScreen = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;

  img {
    max-width: 100px;
  }
`;

export default LoadingScreen;
