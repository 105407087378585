import { Reducer } from 'redux';
// eslint-disable-next-line import/named
import { produce, Draft } from 'immer';
import {
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  UPDATE_QUANTITY
} from '../action-types/basket.actionTypes';
import { Item } from '../types/schemas';
import { STORAGE_KEY_BASKET } from '../lib/constants';
import { StoreAction } from './index';

export type BasketState = {
  items: BasketItem[];
};

export type BasketItem = { item: Item; quantity: number };

type BasketActionReducer = (
  baseState: BasketState,
  draftState: Draft<BasketState>,
  action: StoreAction
) => void;

/**
 * Basket reducer
 */
const basketReducer: Reducer<BasketState, StoreAction> = (
  state = loadBasket(),
  action
) => {
  return produce(state, (draftState: Draft<BasketState>) => {
    switch (action.type) {
      case ADD_TO_BASKET:
        return addToBasket(state, draftState, action);

      case REMOVE_FROM_BASKET:
        return removeFromBasket(state, draftState, action);

      case UPDATE_QUANTITY:
        return updateQuantity(state, draftState, action);

      default:
        return state;
    }
  });
};

/**
 * Load basket from localStorage.
 */
const loadBasket = (): BasketState => {
  const preloadedBasket = localStorage.getItem(STORAGE_KEY_BASKET);

  return preloadedBasket ? JSON.parse(preloadedBasket) : { items: [] };
};

/**
 * Handle the add to basket action.
 */
const addToBasket: BasketActionReducer = (baseState, draftState, action) => {
  const { item, quantity } = action.payload;
  const existingItem = getItem(draftState, item.id);

  if (existingItem) {
    existingItem.quantity += quantity;
  } else {
    // Add as new item
    draftState.items.push({ item, quantity });
  }
};

/**
 * Handle the remove from basket action.
 */
const removeFromBasket: BasketActionReducer = (
  baseState,
  draftState,
  action
) => {
  const { itemId } = action.payload;
  const itemIndex = draftState.items.findIndex(
    ({ item }) => item.id === itemId
  );

  if (itemIndex > -1) {
    draftState.items.splice(itemIndex, 1);
  }
};

/**
 * Handle the update quantity action.
 */
const updateQuantity: BasketActionReducer = (baseState, draftState, action) => {
  const { itemId, quantity } = action.payload;
  const existingItem = getItem(draftState, itemId);

  if (existingItem) {
    existingItem.quantity = quantity;
  }
};

/**
 * Get an item from the basket.
 */
const getItem = (
  basket: BasketState,
  itemId: number
): BasketItem | undefined => {
  return basket.items.find(({ item }) => item.id === itemId);
};

export default basketReducer;
