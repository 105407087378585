import React from 'react';
import styled from 'styled-components';
import Card from '../../../components/Card';
import palette from '../../../theme/palette';
import { Subheading } from '../../../components/typography';
import { Saving } from '../../../lib/calculations';
import { formatMoney } from '../../../lib/utils';
import Subtotal from './Subtotal';

type Props = {
  savings: Saving[];
  totalSavings: number;
};

const Savings: React.FC<Props> = ({ savings, totalSavings }) => (
  <StyledSavings>
    <Subheading>Savings</Subheading>

    {savings.map(
      ({ item, offerName, offerTotalSaving, offerQuantity }, index) => (
        <Card key={index} className="mb-2 saving">
          <div className="saving__name">
            {item.name} {offerName}
            <span className="grey"> (x {offerQuantity})</span>
          </div>

          <div className="saving__amount green">
            - {formatMoney(offerTotalSaving)}
          </div>
        </Card>
      )
    )}

    <Subtotal>
      Total savings: <span className="green">-{formatMoney(totalSavings)}</span>
    </Subtotal>
  </StyledSavings>
);

const StyledSavings = styled.div`
  .saving {
    display: flex;
    justify-content: space-between;
  }

  .green {
    color: ${palette.green};
  }

  .grey {
    color: ${palette.grey};
  }
`;

export default Savings;
