import { Item } from '../types/schemas';
import { BasketItem } from '../reducers/basketReducer';
import { BEANS, COKE } from '../data/items';
import { THREE_FOR_TWO, TWO_FOR_ONE_POUND } from './constants';
import { roundTo2Dp } from './utils';

export type Saving = {
  item: Item;
  offerName: string;
  offerSaving: number;
  offerQuantity: number;
  offerTotalSaving: number;
};
export type Savings = Saving[];

/**
 * Calculate potential savings.
 *
 * NOTE:
 * This is just hardcoded for simplicity. In a real app, such logic would live on the server and
 * the front-end would just receive the savings as JSON every time the basket is updated.
 */
export const calculateSavings = (items: BasketItem[]): Savings => {
  const savings: Savings = [];

  items.forEach(({ item, quantity }) => {
    switch (item.id) {
      // Beans savings
      case BEANS.id: {
        const numSavings = Math.floor(quantity / 3);

        if (numSavings > 0) {
          const saving: Saving = {
            item,
            offerName: THREE_FOR_TWO,
            offerSaving: 0.5,
            offerQuantity: numSavings,
            offerTotalSaving: numSavings * 0.5
          };

          savings.push(saving);
        }
        return;
      }
      // Coke savings
      case COKE.id: {
        const numSavings = Math.floor(quantity / 2);

        if (numSavings > 0) {
          const saving: Saving = {
            item,
            offerName: TWO_FOR_ONE_POUND,
            offerSaving: 0.4,
            offerQuantity: numSavings,
            offerTotalSaving: numSavings * 0.4
          };

          savings.push(saving);
        }

        return;
      }
      default:
        return;
    }
  });

  return savings;
};

/**
 * Calculate item subtotal.
 *
 * NOTE:
 * Again, this sort of logic would live on the server in a real app.
 */
export const calculateItemSubtotal = (item: Item, quantity: number): number => {
  return roundTo2Dp(item.unitPrice * quantity);
};
