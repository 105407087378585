import { Dispatch } from 'redux';
import { StoreAction } from '../reducers';
import { FETCH_PRODUCTS_RECEIVE } from '../action-types/products.actionTypes';
import { Item } from '../types/schemas';
import items from '../data/items';

const SIMULATED_DELAY_MS = 400;

// Simulate an API request.
export const fetchProducts = () => {
  return (dispatch: Dispatch) => {
    setTimeout(() => {
      dispatch(fetchProductsReceive(items));
    }, SIMULATED_DELAY_MS);
  };
};

export const fetchProductsReceive = (products: Item[]): StoreAction => ({
  type: FETCH_PRODUCTS_RECEIVE,
  payload: { products }
});
