import styled from 'styled-components';
import typography from '../../../theme/typography';

const Subtotal = styled.div`
  font-size: 22px;
  font-weight: ${typography.fontWeights.robotoRegular};
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: right;
`;

export default Subtotal;
