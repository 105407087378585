import configureStore from '../lib/configureStore';
import appReducer from '../reducers';
import { ProductsState } from '../reducers/productsReducer';
import { BasketState } from '../reducers/basketReducer';
import { STORAGE_KEY_BASKET } from '../lib/constants';

export type StoreState = {
  basket: BasketState;
  products: ProductsState;
};

// Create the store
const store = configureStore<StoreState>(appReducer);

// Save the basket to localStorage.
const saveBasket = () => {
  const { basket } = store.getState();

  localStorage.setItem(STORAGE_KEY_BASKET, JSON.stringify(basket));
};

// Add store listeners
store.subscribe(saveBasket);

export default store;
