import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppProvider from './components/AppProvider';
import ShopView from './views/shop';
import BasketView from './views/basket';
import { BASKET_ROUTE, SHOP_ROUTE } from './lib/constants';

const App: React.FC = () => (
  <AppProvider>
    <Router>
      <Switch>
        <Route exact path={SHOP_ROUTE}>
          <ShopView />
        </Route>

        <Route path={BASKET_ROUTE}>
          <BasketView />
        </Route>
      </Switch>
    </Router>
  </AppProvider>
);

export default App;
