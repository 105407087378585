import { Reducer } from 'redux';
import { Item } from '../types/schemas';
import { FETCH_PRODUCTS_RECEIVE } from '../action-types/products.actionTypes';
import { StoreAction } from './index';

export type ProductsState = {
  items: Item[] | null;
};

const initialProductsState: ProductsState = {
  items: null
};

const productsReducer: Reducer<ProductsState, StoreAction> = (
  state = initialProductsState,
  action
) => {
  switch (action.type) {
    case FETCH_PRODUCTS_RECEIVE:
      return {
        ...state,
        items: action.payload.products
      };
    default:
      return state;
  }
};

export default productsReducer;
