import { Item } from '../types/schemas';

export const BEANS: Item = {
  id: 1,
  name: 'Beans',
  price: 0.5,
  unitType: 'pcs',
  unitSize: 1,
  unitPrice: 0.5
};

export const COKE: Item = {
  id: 2,
  name: 'Coke',
  price: 0.7,
  unitType: 'pcs',
  unitSize: 1,
  unitPrice: 0.7
};

export const ORANGES: Item = {
  id: 3,
  name: 'Oranges (100g)',
  price: 1.99,
  unitType: 'weight',
  unitSize: 0.1,
  unitPrice: 0.2
};

const items = [BEANS, COKE, ORANGES];

export default items;
