import { Item } from '../types/schemas';
import {
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  UPDATE_QUANTITY
} from '../action-types/basket.actionTypes';
import { StoreAction } from '../reducers';

export const addToBasket = (item: Item, quantity: number): StoreAction => ({
  type: ADD_TO_BASKET,
  payload: { item, quantity }
});

export const removeFromBasket = (itemId: number): StoreAction => ({
  type: REMOVE_FROM_BASKET,
  payload: { itemId }
});

export const updateQuantity = (
  itemId: number,
  quantity: number
): StoreAction => ({
  type: UPDATE_QUANTITY,
  payload: { itemId, quantity }
});
