import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout';
import Container from '../../components/layout/Container';
import { PageTitle } from '../../components/typography';
import {
  basketItemsSelector,
  basketSavingsSelector,
  basketSavingsTotalSelector,
  basketTotalPaySelector
} from '../../store/selectors';
import { SHOP_ROUTE } from '../../lib/constants';
import Divider from '../../components/layout/Divider';
import { formatMoney } from '../../lib/utils';
import Savings from './components/Savings';
import BasketItems from './components/BasketItems';
import Total from './components/Total';

const BasketView: React.FC = () => {
  const items = useSelector(basketItemsSelector);
  const savings = useSelector(basketSavingsSelector);
  const totalSavings = useSelector(basketSavingsTotalSelector);
  const totalPay = useSelector(basketTotalPaySelector);

  return (
    <Layout data-testid="basket-view">
      <Container>
        <PageTitle>Basket.</PageTitle>

        {items.length > 0 ? (
          <>
            <BasketItems />

            <Divider />

            {savings.length > 0 && (
              <>
                <Savings savings={savings} totalSavings={totalSavings} />
                <Divider />
              </>
            )}

            <Total>Total to pay: {formatMoney(totalPay)}</Total>
          </>
        ) : (
          <div>
            <p>No items in basket.</p>
            <Link to={SHOP_ROUTE}>Go to shop.</Link>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default BasketView;
